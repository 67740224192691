import React, { createContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import locales from '../../content/i18n'

export const Context = createContext()

export const useContext = () => React.useContext(Context)

export const AppProvider = ({ locale, children }) => {
  const data = useStaticQuery(graphql`
    query Languages {
      allPrismicHome {
        edges {
          node {
            lang
          }
        }
      }
    }
  `)

  let AVAILABLE_LOCALES = []
  const query = data.allPrismicHome.edges

  const getLanguages = query.map(lang => {
    AVAILABLE_LOCALES.push(lang.node.lang)

    return lang.node.lang
  })

  AVAILABLE_LOCALES = getLanguages

  const globalWindow =
    typeof global.window !== `undefined` ? global.window : undefined

  const browserLocale = globalWindow?.navigator?.language?.split('-')?.[0]

  const matchWithAvailableLocales =
    AVAILABLE_LOCALES.find(e => e.split('-')[0] === browserLocale) ||
    AVAILABLE_LOCALES[0]

  const currentLocale = locale || matchWithAvailableLocales

  return (
    <Context.Provider value={{ locales, locale: currentLocale }}>
      {children}
    </Context.Provider>
  )
}
