// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-indexes-about-jsx": () => import("./../../../src/indexes/about.jsx" /* webpackChunkName: "component---src-indexes-about-jsx" */),
  "component---src-indexes-academy-jsx": () => import("./../../../src/indexes/academy.jsx" /* webpackChunkName: "component---src-indexes-academy-jsx" */),
  "component---src-indexes-articles-jsx": () => import("./../../../src/indexes/articles.jsx" /* webpackChunkName: "component---src-indexes-articles-jsx" */),
  "component---src-indexes-contact-jsx": () => import("./../../../src/indexes/contact.jsx" /* webpackChunkName: "component---src-indexes-contact-jsx" */),
  "component---src-indexes-courses-jsx": () => import("./../../../src/indexes/courses.jsx" /* webpackChunkName: "component---src-indexes-courses-jsx" */),
  "component---src-indexes-events-jsx": () => import("./../../../src/indexes/events.jsx" /* webpackChunkName: "component---src-indexes-events-jsx" */),
  "component---src-indexes-home-jsx": () => import("./../../../src/indexes/home.jsx" /* webpackChunkName: "component---src-indexes-home-jsx" */),
  "component---src-indexes-pain-finder-jsx": () => import("./../../../src/indexes/pain-finder.jsx" /* webpackChunkName: "component---src-indexes-pain-finder-jsx" */),
  "component---src-indexes-privacy-jsx": () => import("./../../../src/indexes/privacy.jsx" /* webpackChunkName: "component---src-indexes-privacy-jsx" */),
  "component---src-indexes-serious-games-jsx": () => import("./../../../src/indexes/serious-games.jsx" /* webpackChunkName: "component---src-indexes-serious-games-jsx" */),
  "component---src-indexes-terms-jsx": () => import("./../../../src/indexes/terms.jsx" /* webpackChunkName: "component---src-indexes-terms-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-article-article-jsx": () => import("./../../../src/templates/Article/Article.jsx" /* webpackChunkName: "component---src-templates-article-article-jsx" */),
  "component---src-templates-course-course-jsx": () => import("./../../../src/templates/Course/Course.jsx" /* webpackChunkName: "component---src-templates-course-course-jsx" */),
  "component---src-templates-event-event-jsx": () => import("./../../../src/templates/Event/Event.jsx" /* webpackChunkName: "component---src-templates-event-event-jsx" */),
  "component---src-templates-serious-games-serious-games-jsx": () => import("./../../../src/templates/SeriousGames/SeriousGames.jsx" /* webpackChunkName: "component---src-templates-serious-games-serious-games-jsx" */)
}

