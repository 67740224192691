module.exports = {
  'pt-pt': {
    default: true,
    path: 'pt',
    locale: 'pt-pt',
    siteLanguage: 'pt',
    ogLang: 'pt_PT',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
  },
}
